import * as React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import PageHeader from "../../components/pageheader"
import * as styles from "../../styles/access.module.css"
import { useLangContext } from "../../context/langContext"

const AccessSinglePage = ({ pageContext }) => {
    const { lang } = useLangContext()
    const data = pageContext?.data

    let accessBread = { label: "Access", link: "/access" }
    if (lang === "id") {
        accessBread = {
            ...accessBread,
            label: "Akses",
            link: "/id/access",
        }
    }

    return (
        <Layout activeMenuId={6}>
            {data?.blocks?.map((item, i) => {
                switch (item?.name) {
                    case "acf/cover-main":
                        const coverMain = item?.coverMain
                        return (
                            <React.Fragment key={i}>
                                <Seo title={coverMain?.title} />
                                <PageHeader
                                    title={coverMain?.title}
                                    subTitle={coverMain?.text}
                                    image={coverMain?.img?.mediaItemUrl}
                                    breadcrumbs={[
                                        {
                                            label: accessBread.label,
                                            link: accessBread.link,
                                        },
                                        {
                                            label: coverMain?.title,
                                            link: null,
                                        },
                                    ]}
                                />
                            </React.Fragment>
                        )
                    case "acf/acfimage":
                        const imageBlock = item?.imageBlock
                        return (
                            <div key={i} className="container mx-auto">
                                <div className="text-center">
                                    <div>
                                        <img
                                            src={
                                                imageBlock?.img
                                                    ?.mediaItemUrl
                                            }
                                            alt={
                                                imageBlock?.img
                                                    ?.altText
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        )
                    case "acf/col-headline":
                        const colHeadline = item?.colHeadline
                        return (
                            <div key={i} className="container mx-auto">
                                <div className={`px-4 ${styles.text}`}>
                                    <h2>{colHeadline?.title}</h2>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: colHeadline?.text,
                                        }}
                                    />
                                </div>
                            </div>
                        )
                }
            })}
        </Layout>
    )
}

export default AccessSinglePage
